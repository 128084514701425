<template>
  <b-modal
    ref="remove-modal"
    hide-title
    ok-only
    ok-title="Zavrieť"
    size="lg"
    v-if="this.itemToRemove"
    @ok="$emit('remove', itemToRemove)"
  >
    <template #modal-header="{ close }">
      <h5>{{ $t(headerText) }}</h5>
      <svg-cross-icon @click="close()" />
    </template>
    <div class="m-5">
      <slot></slot>
    </div>
    <template #modal-footer="{ ok, cancel }">
      <b-button
        class="modal-btn text-uppercase"
        variant="primary"
        @click="ok()"
        >{{ $t("yes") }}</b-button
      >
      <b-button
        class="modal-btn text-uppercase"
        variant="outline-danger"
        @click="cancel()"
        >{{ $t("no") }}</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    "svg-cross-icon": () => import("/assets/icons/cross.svg?inline"),
  },

  props: {
    headerText: {
      required: false,
      type: String,
      default: "",
    },
  },

  data() {
    return {
      itemToRemove: null,
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    isAdmin() {
      return this.user.type === "teacher";
    },
  },

  methods: {
    showModal(item) {
      this.itemToRemove = item;
      this.$nextTick(() => {
        this.$refs["remove-modal"].show();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  cursor: pointer;
}

h4 {
  font-family: Boing !important;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: #2b2e4a;
}

::v-deep .modal-header {
  border-bottom: 0 none;
}

::v-deep .modal-content {
  top: 9.37rem;
}

::v-deep .modal-body {
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.31rem;
    margin-top: auto !important;
    margin-bottom: auto !important;
    align-items: center;

    color: #000000;
  }
  .modal-box {
    align-items: center;
    width: 35.56rem;
    height: 5.875rem;
    left: 38.563rem;
    top: 52.938rem;

    background: #fff5d1;
    border: 1px solid #e6d8a7;
    box-sizing: border-box;
    border-radius: 0.25rem;
  }
  .modal-box-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1.125rem;

    letter-spacing: -0.05em;

    color: #cab979;
  }
}
input {
  border: none;
  border-bottom: 1px solid #cadeea;
  width: 100%;
  &::placeholder {
    color: rgba(156, 161, 174, 0.4) !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.688rem;
  }
  &:focus {
    outline: 0;
  }
  &:read-only {
    background-color: transparent;
  }
}

::v-deep .modal-content {
  border-style: solid;
  border-width: 1px;
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
  border-image: linear-gradient(90.01deg, #0064ff 60.75%, #fb2564 79.89%) 0 0
    100% 0/0 0 4.53px 0 stretch;
}

::v-deep .modal-footer {
  border-top: 0 none !important;
  align-items: left;
}

.modal-btn {
  width: 13.813rem;
  height: 3.375rem;
  left: 48.875rem;
  top: 47.938rem;
}

.box-content {
  margin: auto;
  padding: 1.25rem;
  justify-content: center;
}

.box-bottom {
  width: 100%;
  background: #fff5d1;
  border: 1px solid #e6d8a7;
  box-sizing: border-box;
  align-items: center;
  border-radius: 0.25rem;
}
</style>
